import React, { Component } from 'react'
import MaandelijkseOmzetChart from '../Graphs/MaandelijkseOmzetChart';

export class MaandelijkseOmzet extends Component {
    render() {
        return (
            <div className="business__box business__box--wklomzet">
                <div>
                    <p className="business__box--lefttext">Maandelijkse omzet</p> 
                </div>
                <MaandelijkseOmzetChart />
            </div>
            
        )
    }
}

export default MaandelijkseOmzet
