import React from 'react';
// import { Provider } from 'react-redux';
// import Instagram from '../Components/Instagram'; 


class Socials extends React.Component  {

    render(){
        return(
            <h1>In progress...</h1>
        )
    }
}
    


export default Socials;
