import React, { Component } from 'react'
import TotaleSalesChart from '../Graphs/TotaleSalesChart'

export class TotaleSales extends Component {
    render() {
        return (
            <div className="business__box business__box--ttlsales">
                <div className="business__container--small">
                    <p className="business__box--lefttext">Totale Sales</p>
                </div>
                <TotaleSalesChart className="ttlsales__chart" />
                <div className="ttlsales__factuur">
                    <p className="ttlsales__header">
                        €2595
                    </p>
                    <p className="ttlsales__subtitle">
                        GEFACTUREERD
                    </p>
                    <p className="ttlsales__header">
                        23
                    </p>
                    <p className="ttlsales__subtitle">
                        FACTUREN
                    </p> 
                </div>
            </div>
        )
    }
}

export default TotaleSales
