//React imports
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { render } from '@testing-library/react';

//Pages imports
import Business from './Pages/Business';
import Socials from './Pages/Socials';
import Progressie from './Pages/Progressie';
import Projecten from './Pages/Projecten';

//Components imports
import Nav from './Components/Nav';

//SCSS imports
import './app.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch> 
          <Route path="/" exact component={Business}/> 
          <Route path="/projecten" component={Projecten}/> 
          <Route path="/progressie" component={Progressie}/> 
          <Route path="/socials" component={Socials}/> 
        </Switch>
      </div>
    </Router>

  );
}

export default App;
