import React, { Component } from 'react';
import OmzetChart from '../Graphs/OmzetChart';

const OmzetData = (props) => {
    return <div className="business__box--stats">{props.omzet}</div>
}

export class Omzet extends Component {

    constructor(props) {
        super(props)
        this.state = {
            omzet: 60000
        }
    }

    render() {
        return (
            <div className="business__box business__box--omzet">
                <div className="business__container--small">
                    <p className="business__box--lefttext">Omzet</p>
                    <p className="business__box--righttext">Maandelijks</p>
                </div>
                <OmzetData omzet={this.state.omzet} />
                <OmzetChart />
            </div>
        )
    }
} 

export default Omzet;
