import React from 'react'
import { Link } from "react-router-dom";

const NavLine = () => (
    <hr
        style={{
            color: '#707070',
            height: 1, 
            opacity: '25%', 
            margin: '30px 0 0 0'
        }}
    />
);

function Nav() {
    return (
        <React.Fragment>
            <img className="nav__img" src={require('../img/brandfirm-logo.svg')} alt="logo" />

            <NavLine />

            <nav className="nav">
                <ul className="nav__links">
                    <Link className="nav__link hvr-glow" to="/" exact><li>Business</li></Link>
                    {/* <Link className="nav__link hvr-glow" to="/projecten" exact><li>Projecten</li></Link> */}
                    <Link className="nav__link hvr-glow" to="/progressie" exact><li>Progressie</li></Link>
                    {/* <Link className="nav__link hvr-glow" to="/socials" exact><li>Socials</li></Link> */}
                </ul>
            </nav>
        </React.Fragment>
    )
}

export default Nav;
