import React, { Component } from 'react'
import ActieveProjectenChart from '../Graphs/ActieveProjecten'

export class ActieveProjecten extends Component {
    render() {
        return (
            <div className="business__box business__box--actprj">
                <div className="business__container--small">
                    <p className="business__box--lefttext">Actieve Projecten</p>
                    <p className="business__box--righttext">Maandelijks doel</p>
                </div>
                <p className="business__box--stats">24</p>
                <ActieveProjectenChart />
            </div>
        )
    }
}

export default ActieveProjecten
