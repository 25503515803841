import React, { Component } from 'react';

 
class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hits: [],
    };
  }
 
  componentDidMount() {
    fetch("https://brandfirm.simplicate.nl/api/v2/crm/organization", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Secret": "UnAJoWSnuCI7QoqN3APc39wTPRe8D9Qo",
        "Authentication-Key": "y9tSZavi8IUvNnQs6PjciCtt0QvutV4R"
      }
    })
    .then(response => response.json())
    .then(data => this.setState({ hits: data.hits }))
    .catch(function(err) {
      console.log("Fetch Error :-S", err);
    });
  }
 
  render() {
    const { hits } = this.state;
  
    return (
      <ul>
        {hits.map(hit =>
          <li key={hit.objectID}>
            <a href={hit.url}>{hit.title}</a>
          </li>
        )}
      </ul>
    );
  }
}
 
export default Items;