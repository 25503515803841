import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const data = [
  {
    name: 'Januari', actueel: 4000, verwacht: 2400, amt: 2400,
  },
  {
    name: 'Febrauri', actueel: 3000, verwacht: 1398, amt: 2210,
  },
  {
    name: 'Maart', actueel: 2000, verwacht: 9800, amt: 2290,
  },
  {
    name: 'April', actueel: 2780, verwacht: 3908, amt: 2000,
  },
  {
    name: 'Mei', actueel: 1890, verwacht: 4800, amt: 2181,
  },
  {
    name: 'Juni', actueel: 2390, verwacht: 3800, amt: 2500,
  },
  {
    name: 'Juli', actueel: 3490, verwacht: 4300, amt: 2100,
  },
];

export default class MaandelijkseOmzetChart extends PureComponent {

  render() {
    return (
      <LineChart
        width={1000}
        height={350}
        data={data}
        margin={{
          top: 50, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="actueel" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="verwacht" stroke="#82ca9d" />
      </LineChart>
    );
  }
}
