//import react
import React from 'react';

//import HTTP related 

//import Business Components
import Sales from './BusinessComponents/Sales';
import Omzet from './BusinessComponents/Omzet';
import ActieveProjecten from './BusinessComponents/ActieveProjecten';
import TotaleSales from './BusinessComponents/TotaleSales';
import MaandelijkseOmzet from './BusinessComponents/MaandelijkseOmzet';

class Business extends React.Component {

  render() {

      return (     
        <div className="business__container">
          <div className="business__row">
            <Sales />
            <Omzet />
            <ActieveProjecten />
          </div>
          <div className="business__row">
            <TotaleSales />
            <MaandelijkseOmzet />  
          </div>
        </div>
      )
    }  
  }
  

export default Business;
