import React, { Component } from 'react';
import Items from '../Components/items';

export default class Progressie extends Component {

    constructor(props) {
        super(props);
        this.state = {
          items: []
        }
      }
          
      // componentDidMount() {   
      //   fetch('https://api.hunter.io/v2/')
      //   .then(res => res.json())
      //   .then((data) => {
      //   this.setState({ items: data })
      //   })
      //   .catch(console.log)
      // }

    render() {
        return (
            <div>
                <Items items={this.state.items} />
            </div>      
        )
    }
}