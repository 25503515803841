import React, { Component } from 'react';

    const Filler = (props) => {
        return <div className="graph__progressbar-filler" style={{width: `${props.percentage}%` }} /> 
    }

    const ProgressBar = (props) => {
        return (
            <div className="graph__progressbar"  >
                <Filler percentage={props.percentage} />
            </div>
        )
    }
    
export class Sales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            percentage: 60,
        }
    }

    render() {
        return (
            <div className="business__box business__box--sales">
                <div className="business__container--small">
                    <p className="business__box--lefttext">Sales</p>
                    <p className="business__box--righttext">Maandelijks doel</p>
                </div>
                <p className="business__box--stats">60%</p>
                <ProgressBar percentage={this.state.percentage} />
            </div>
        )
    }
}

export default Sales
